<template>
  <div>
    <b-card
      :title="
        type === 'add'
          ? 'Подключение Google Analytics'
          : 'Редактировать Google Analytics'
      "
      class="col col-md-6"
      v-if="type === 'add' || getCurrentIntegration"
    >
      <b-row>
        <b-col md="12" class="mb-2">
          <label for="google-name"> Название: </label>
          <b-form-input
            placeholder="Введите название"
            id="gooogle-name"
            v-model="googleAnalytics.name"
          >
          </b-form-input>
        </b-col>
        <b-col md="12" class="mb-2">
          <label for="google-url"> Measurement ID: </label>
          <b-form-input
            placeholder="G-11111111"
            id="google-url"
            v-model="googleAnalytics.url"
          >
          </b-form-input>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="type === 'add' ? googleAdd() : googleEdit()"
          >
            {{
              type === "add" ? "Подключить Google Analytics" : "Редактировать"
            }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  data() {
    return {
      googleAnalytics: {
        type: "googleAnalytics",
        measurementId: "",
        name: "",
      },
      type: "add",
    };
  },
  computed: {
    ...mapGetters("integrations", ["getCurrentIntegration"]),
  },
  watch: {
    getCurrentIntegration(newVal) {
      if (Object.keys(newVal).length) {
        this.googleAnalytics = newVal;
        this.type = "edit";
      }
    },
  },
  metaInfo() {
    return {
      title: "moovee - Google analytics интеграция",
    };
  },
  methods: {
    ...mapActions("integrations", [
      "addIntegration",
      "requestCurrentIntegration",
      "editIntegration",
    ]),
    googleAdd() {
      this.addIntegration(this.googleAnalytics).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Google analytics подключен",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
    googleEdit() {
      this.editIntegration({
        id: this.$route.params.id,
        data: this.googleAnalytics,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Google analytics редактирован",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
  },
  created() {
    if (this.$route.params.id !== "add") {
      this.requestCurrentIntegration(this.$route.params.id);
    }
  },
};
</script>
